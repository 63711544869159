import React from 'react'
import Typography from '@mui/material/Typography'

type PersonalValueProps = {
    children: string | number | React.ReactElement | null | undefined
    color: string
}

const PersonalValue = ({ children, color }: PersonalValueProps): JSX.Element => (
    <Typography color={color}>{children}</Typography>
)

export default PersonalValue
