import Grid from '@mui/material/Grid'
import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Link from '../Link'
import CopyLink from '../CopyLink'
import PersonalLabel from './PersonalLabel'
import PersonalValue from './PersonalValue'

export type PersonalFieldProps = {
    label: string
    value: string | number | React.ReactElement | null | undefined
    isLoading: boolean
    linkHref?: string
    labelColumns?: { xs?: number; md?: number; lg?: number; xl?: number }
    valueColumns?: { xs?: number; md?: number; lg?: number; xl?: number }
    valueColor?: string
    showCopyIcon?: boolean
}

const PersonalField = ({
    label,
    value,
    linkHref,
    isLoading,
    labelColumns = { xs: 4 },
    valueColumns = { xs: 8 },
    valueColor = 'text.secondary',
    showCopyIcon = false,
}: PersonalFieldProps): JSX.Element => (
    <Grid container item xs={12}>
        <Grid item {...labelColumns}>
            {isLoading ? <Skeleton width={100} /> : <PersonalLabel>{label}</PersonalLabel>}
        </Grid>
        <Grid item {...valueColumns}>
            {isLoading ? (
                <Skeleton />
            ) : linkHref && value && typeof value === 'string' ? (
                <Link href={`${linkHref}${value}`} target="_blank" rel="noreferrer">
                    {value}
                </Link>
            ) : (
                <Box display="flex" flexDirection="row">
                    <PersonalValue color={valueColor}>{value}</PersonalValue>
                    {showCopyIcon && !!value && typeof value === 'string' && (
                        <Box marginTop={-1} marginLeft={2}>
                            <CopyLink
                                text={value}
                                toolTipText="Copy RISE ID"
                                icon={<ContentCopyIcon sx={{ color: 'text.secondary', fontSize: 'inherit' }} />}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Grid>
    </Grid>
)

export default PersonalField
