import type { SystemCssProperties } from '@mui/system'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export type LegendColorProps = {
    label: string
    color: string
    fontColor?: string
    padding?: string
    fontStyle?: SystemCssProperties['fontStyle']
    textDecoration?: SystemCssProperties['textDecoration']
}

const LegendColor = ({
    label,
    color,
    fontColor,
    padding = '10px',
    fontStyle,
    textDecoration,
}: LegendColorProps): JSX.Element => (
    <Box
        sx={{
            height: '100%',
            width: '100%',
            backgroundColor: color,
            paddingX: padding,
            whiteSpace: 'nowrap',
        }}
    >
        <Typography
            sx={{ color: fontColor, fontStyle, textDecoration, '@media print': { fontSize: '16px' } }}
            variant="caption"
            fontWeight="medium"
        >
            {label}
        </Typography>
    </Box>
)

export default LegendColor
