import React from 'react'
import LinkIcon from '@mui/icons-material/Link'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import useToastContext from '../lib/hooks/useToastContext'

type CopyLinksProps = {
    text: string
    icon?: React.ReactNode
    toolTipText?: string
}

const CopyLink = ({ text, icon, toolTipText = 'Copy Link' }: CopyLinksProps): JSX.Element => {
    const toastContext = useToastContext()

    return (
        <Tooltip title={toolTipText}>
            <IconButton
                edge="start"
                onClick={async () => {
                    await navigator.clipboard.writeText(text)
                    toastContext?.addToast({
                        severity: 'success',
                        message: 'Copied link to clipboard',
                    })
                }}
            >
                {icon || <LinkIcon sx={{ color: 'text.secondary' }} />}
            </IconButton>
        </Tooltip>
    )
}

export default CopyLink
